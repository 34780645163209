import Layout from '../components/layout';
import React from 'react';
import Seo from '../components/seo';
import { calculateAge } from '../util/date';

const Services = () => {
  return (
    <section className="section" id="services">
      <div className="section-heading has-text-centered my-6">
        <h3 className="title is-2">Services</h3>
        <h4 className="subtitle is-5">What can I do for you?</h4>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="box">
              <div className="content">
                <h4 className="title is-5">Front End Web Development</h4>
                Develop Front End using latest standards with HTML5/CSS3 with
                added funtionality using JavaScript and React.js.
              </div>
            </div>
          </div>
          <div className="column">
            <div className="box">
              <div className="content">
                <h4 className="title is-5">Back End Web Development</h4>
                Develop Back End application/service using Spring Boot,
                Hibernate and MySQL server or Mongo DB databases.
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="box">
              <div className="content">
                <h4 className="title is-5">Mobile App Development</h4>
                Develop Cross Platform Mobile App using Flutter for Android or
                iOS, with custom backend or Google firestore.
              </div>
            </div>
          </div>
          <div className="column">
            <div className="box">
              <div className="content">
                <h4 className="title is-5">E-commerce Application</h4>
                Develop Fully functional E-commerce Website using Wordpress and
                WooCommerce or with custom technologies.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function AboutMe() {
  return (
    <div id="about" className="section">
      <div className="section-heading has-text-centered">
        <h3 className="title is-2">About Me</h3>
        <h4 className="subtitle is-5">Jack of all trades, master of "some"</h4>
        <div className="container">
          <p>
            Web developer with more than <strong>{`${calculateAge(new Date('09/01/2014'))}+`} years</strong> of
            well-rounded experience with a degree in the field of
            <strong> Computer Science</strong>, extensive knowledge of modern
            Web techniques and love for <strong>Tea</strong>. Always looking for an
            opportunity to work and upgrade, as well as being involved in an
            organization that believes in gaining a competitive edge and giving
            back to the community.
          </p>
        </div>
      </div>
    </div>
  );
}

const About = () => {
  return (
    <Layout>
      <Seo title="About Shishir Singh" />
      <div className="container">
        <AboutMe></AboutMe>
        <Services />
      </div>
    </Layout>
  );
};

export default About;
